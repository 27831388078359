import React, { useState, useEffect,useContext } from 'react';
import axios from 'axios';
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { AuthContext } from "../../context/Auth/AuthContext";
import { endpoint } from '../../App';

const CustomLegend = ({ payload }) => {
  return (
    <div style={{ color: 'black', fontSize: '10px' }}>
      {payload.map((entry, index) => (
        <div key={`item-${index}`} style={{ marginBottom: '5px' }}>
          <span
            style={{
              backgroundColor: entry.payload.fill,
              width: '10px',
              height: '10px',
              display: 'inline-block',
              marginRight: '5px',
            }}
          />
          <span>{`${entry.payload.categoria}: ${entry.payload.quantidade}`}</span>
        </div>
      ))}
    </div>
  );
};
const Chart3 = () => {
  const [dados, setDados] = useState([]);
  const [dadosParaGrafico, setDadosParaGrafico] = useState([]);
  const [totalAtendimentos, setTotalAtendimentos] = useState(0);
  const [company, setCompany] = useState(null);
	const { user } = useContext(AuthContext); 
	const nomedaempresa = company
	useEffect(() => {
		if (user && user.company) {
		  setCompany(user.company.name); 
		}
	  }, [user]);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(endpoint + "/query", {
          "query": {
            "Provedor": nomedaempresa,
          }
        }, {
          headers: { 'Content-Type': 'application/json; charset=utf-8' }
        });

        const data = response.data;
        setDados(data);

        // Contar ocorrências de cada Diagnostico_Finalizacao
        const contagem = data.reduce((acc, item) => {
          const diagnostico = item.Diagnostico_Finalizacao;
          if (diagnostico) {
            acc[diagnostico] = (acc[diagnostico] || 0) + 1;
          }
          return acc;
        }, {});

        // Transformar a contagem em formato de gráfico, excluindo itens com quantidade 0
        const dadosParaGrafico = Object.keys(contagem)
          .filter(chave => contagem[chave] > 0) // Filtra chaves com valor maior que 0
          .map(chave => ({
            categoria: chave,
            quantidade: contagem[chave]
          }));

        setDadosParaGrafico(dadosParaGrafico);

        const total = dadosParaGrafico.reduce((acc, item) => acc + item.quantidade, 0);
        setTotalAtendimentos(total);
      } catch (error) {
        console.error('Erro ao buscar dados:', error);
      }
    };

    fetchData();
  }, [nomedaempresa]);
  


  const COLORS = ['#0088FE','#384049','#BFD641', '#7F5571','#4A46BD','#DC4444','#8F1EAF','#E6089C','#00C49F', '#FFBB28', '#FF8042','#61DA20','#6F965A','#E21314','#EAC11C','#1E9716','#5DE2E7','#CC6CE7','#FE9900','#060270'];
  const PADDING_ANGLE = 3;
  const INNER_RADIUS = 40;
  
  return (
    <React.Fragment>
    <ResponsiveContainer width="100%" height="100%" style={{color:"black"}}>
      <h3>Diagnóstico de Finalização</h3>
      
      <PieChart width={600} height={600}>
        <Pie
          data={dadosParaGrafico}
          cx="50%"
          cy="50%"
          dataKey="quantidade"
          nameKey="categoria"
          outerRadius={260}
          innerRadius={INNER_RADIUS}
          fill="#8884d8"
          label
          paddingAngle={PADDING_ANGLE}         
        >
          {dadosParaGrafico.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip contentStyle={{ backgroundColor: 'white', border: '1px solid #ccc' }} />
        <Legend
        content={<CustomLegend />}
        wrapperStyle={{ fontSize: '11px', lineHeight: '16px' ,color:"white" }}
        layout="vertical"
        align="right"
        legendType="plain"
      />
      </PieChart>
     
      </ResponsiveContainer>
      </React.Fragment>
  );
};

export default Chart3;
