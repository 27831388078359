import React, { useState, useEffect, useRef, useContext } from "react";
import { toast } from "react-toastify";
import { useTheme } from "@material-ui/core/styles";
import moment from "moment";
import 'moment/locale/pt-br'
import {
	BarChart,
	CartesianGrid,
	Bar,
	XAxis,
	YAxis,
	Label,
	ResponsiveContainer,
} from "recharts";
import { startOfHour, parseISO, format,subDays, addDays } from "date-fns";
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { i18n } from "../../translate/i18n";

import Title from "./Title";
import useTickets from "../../hooks/useTickets";
import axios from "axios";
import { AuthContext } from "../../context/Auth/AuthContext";
import { endpoint } from '../../App';

const getTodayDate = () => {
    return format(new Date(), 'yyyy-MM-dd'); 
};
const getYesterdayDate = () => {
    const yesterday = subDays(new Date(), 1);
    return format(yesterday, 'yyyy-MM-dd');
};
const hoje = getTodayDate();
const ontem = getYesterdayDate();

const Chart = () => {
	const theme = useTheme();
	// const date = useRef(new Date().toISOString());
	// const { tickets } = useTickets({ date: date.current });
	const [atendimentos , setAtendimentos] = useState(0)
	const { user } = useContext(AuthContext); 
	const [company, setCompany] = useState(null);
	const nomedaempresa = company
	
	useEffect(() => {
		if (user && user.company) {
		  setCompany(user.company.name); 
		}
	  }, [user]);

	//Query para pegar atendimentos diario mas com o horario   
	// "DataHora_InicioAtend_ISO": {
	// 	"$gte": hoje + "T00:00:00:000Z",
	// 	"$lte": hoje + "T23:59:59.999Z"
	// }
	
	//Grafico com atendimentos por hora do dia
	// const atendimentosDia = async () => {
	// 	try {
	// 	const hoje = format(new Date(), 'yyyy-MM-dd')
	// 	const ontem = format(subDays(new Date(), 1), 'yyyy-MM-dd')
	// 	const seguinte = format(addDays(new Date(), 1), 'yyyy-MM-dd')
	// 	console.log(ontem)
	// 	console.log(hoje)
	// 	console.log(seguinte)
	// 	const response = await axios.post('http://apiupsoft.upcall.com.br/registros/query', {
	// 		"query": {
	// 		  "DataHora_InicioAtend_ISO": {
	// 			"$gte": hoje + "T03:00:00:000Z",
	// 			"$lte": seguinte + "T02:59:59.999Z"
	// 		  },
	// 		  "Provedor":  nomedaempresa
	// 		}
	// 	  }, {
	// 		headers: { 'Content-Type': 'application/json; charset=utf-8' }
	// 	  });
		  
	// 	  const data = response.data
	// 	  console.log(data)
	// 	  setAtendimentos(data.length)
	// 	  // Processar os dados para agrupar por hora
	// 		const hourlyCounts = Array(24).fill(0); // Array com 24 horas inicializadas com 0
  
	// 		data.forEach(atendimento => {
	// 		  const dataInicio = new Date(atendimento.DataHora_InicioAtend_ISO);
	// 		  dataInicio.setUTCHours(dataInicio.getUTCHours() - 3);

	// 		  const hora = dataInicio.getUTCHours()
	// 		  //const hora = new Date(atendimento.DataHora_InicioAtend_ISO).getUTCHours();
	// 		  hourlyCounts[hora]++;
	//   });
  
	// Atualizar o estado com os dados recebidos
	// 	  setChartData(prevData =>
	// 		prevData.map((entry, index) => ({
	// 		  ...entry,
	// 		  amount: hourlyCounts[index] || 0
	// 		}))
	// 	  );
  
		  
	// 	} catch (error) {
	// 	  console.error('Erro ao buscar dados:', error);
	// 	  toast.error('Ocorreu um erro ao tentar buscar os dados.');
	// 	}
	//   };
	//   atendimentosDia()
	const [mesAtual, setMesAtual] = useState()
	useEffect(() => {
		const nomeMes = moment().format('MMMM');
		console.log(nomeMes);
		setMesAtual(nomeMes)
	}, []);
	useEffect(() => {
		const atendimentosPorDia = async () => {
			try {
				const hoje = format(new Date(), 'yyyy-MM-dd');
				const primeiroDiaDoMes = format(new Date(new Date().getFullYear(), new Date().getMonth(), 1), 'yyyy-MM-dd');
				
				const ultimoDiaDoMes = format(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0), 'yyyy-MM-dd');
				console.log(primeiroDiaDoMes)
				console.log(ultimoDiaDoMes)
				const response = await axios.post(endpoint + "/query", {
					"query": {
						"DataHora_InicioAtend_ISO": {
							"$gte": primeiroDiaDoMes + 'T00:00:00:000Z',
							"$lte": ultimoDiaDoMes + 'T23:59:59.999Z'
						},
						"tipo_atendimento": { "$in": ["CHAT", "LIGAÇÃO"] },
						"Provedor": nomedaempresa
					}
				}, {
					headers: { 'Content-Type': 'application/json; charset=utf-8' }
				});
				
				const data = response.data;
				setAtendimentos(data.length)

				// // const monthlyCounts = Array.from({ length: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getDate() }, (_, index) => ({
				// // 	date: format(new Date(new Date().getFullYear(), new Date().getMonth(), index + 1), 'yyyy-MM-dd'),
				// // 	count: 0
				// // }));
				// const monthlyCounts = Array.from({ length: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getDate() }, (_, index) => ({
				// 	date: format(new Date(new Date().getFullYear(), new Date().getMonth(), index + 1), 'yyyy-MM-dd'),
				// 	count: 0
				//   }));
	
				// data.forEach(atendimento => {
				// 	const dataInicio = new Date(atendimento.DataHora_InicioAtend_ISO);
					
				// 	console.log(dataInicio)
				// 	const dia = dataInicio.getDate();
				// 	if (dia > 0 && dia <= monthlyCounts.length) {
				// 		monthlyCounts[dia - 1].count++;
				// 	}
				// });
				// console.log('Contagens mensais:', monthlyCounts);

				const contagens = [];
				data.forEach(atendimento => {
					const dataInicio = new Date(atendimento.DataHora_InicioAtend_ISO);
					dataInicio.setUTCHours(dataInicio.getUTCHours() - 3);
					const dia = dataInicio.getDate();

					if (!contagens[dia - 1]) {
						contagens[dia - 1] = {
							time: format(dataInicio, 'dd/MM'),
							amount: 1,
						};
					} else {
						contagens[dia - 1].amount++;
					}
				});
				console.log(contagens)
				// Filtrar apenas dias com atendimentos
				setChartData(contagens.filter(Boolean)); // remove `undefined`
				// setChartData(prevData =>
				// 	prevData.map((entry, index) => ({
				// 	  ...entry,
				// 	  amount: hourlyCounts[index] || 0
				// 	}))
				//   );
				// Atualizar o estado com os dados agrupados
				// setChartData(monthlyCounts.map(item => ({
				// 	time: format(new Date(item.date), 'dd/MM'), // Formato para exibir dia/mês
				// 	amount: item.count
				// })));
				
				// setChartData(monthlyCounts.map(item => ({
				// 	time: format(new Date(item.date), 'dd/MM'),
				// 	amount: item.count
				//   })));
			} catch (error) {
				console.log(error)
				console.error('Erro ao buscar dados:', error);
				toast.error('Ocorreu um erro ao tentar buscar os dados.');
			}
		};
	
		atendimentosPorDia();
	},[nomedaempresa])


	// const enviarQueryDiaria = async () => {
	// 	try {
	// 	  // Faça uma única requisição para o dia inteiro
	// 	  const response = await axios.post('http://apiupsoft.upcall.com.br/registros/query', {
	// 		query: {
	// 		  DataHora_InicioAtend_ISO: {
	// 			$gte: ontem + "T23:59:59.999Z",
	// 			$lte: hoje + "T23:59:59.999Z"
	// 		  },
	// 		  Provedor: nomedaempresa
	// 		}
	// 	  }, {
	// 		headers: { 'Content-Type': 'application/json; charset=utf-8' }
	// 	  });
	// 	  console.log("Ontem:", ontem)
	// 	  console.log("Hoje:", hoje)
	// 	  const atendimentos = response.data;
	  
	// 	  // Processar os dados para agrupar por hora
	// 	  const hourlyCounts = Array(24).fill(0); // Array com 24 horas inicializadas com 0
	  
	// 	  atendimentos.forEach(atendimento => {
	// 		const dataInicio = new Date(atendimento.DataHora_InicioAtend_ISO);
	// 		dataInicio.setUTCHours(dataInicio.getUTCHours() - 3);

	// 		const hora = dataInicio.getUTCHours()
	// 		//const hora = new Date(atendimento.DataHora_InicioAtend_ISO).getUTCHours();
	// 		hourlyCounts[hora]++;
	// 	  });
	  
	// 	  // Atualizar o estado com os dados recebidos
	// 	  setChartData(prevData =>
	// 		prevData.map((entry, index) => ({
	// 		  ...entry,
	// 		  amount: hourlyCounts[index] || 0
	// 		}))
	// 	  );
	  
	// 	} catch (error) {
	// 	  console.error('Erro ao enviar a consulta diária:', error);
	// 	  alert('Ocorreu um erro ao tentar enviar a consulta. Verifique o console para mais detalhes.');
	// 	}
	// }



	//  useEffect(() => {
	//    enviarQueryDiaria();
	//  }, [nomedaempresa,atendimentos]);
  
	//Chart para atendimentos por dia
	//  const [chartData, setChartData] = useState(
	// 	Array.from({ length: 24 }, (_, index) => ({
	// 	  time: `${String(index).padStart(2, '0')}:00`,
	// 	  amount: 0
	// 	}))
	//   );

	const [chartData, setChartData] = useState(
		Array.from({ length: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getDate() }, (_, index) => ({
		  time: format(new Date(new Date().getFullYear(), new Date().getMonth(), index + 1), 'dd/MM'), // Formato dia/mês
		  amount: 0
		}))
	  );
	// useEffect(() => {
	// 	setChartData(prevState => {
	// 		let aux = [...prevState];

	// 		aux.forEach(a => {
	// 			tickets.forEach(ticket => {
	// 				format(startOfHour(parseISO(ticket.createdAt)), "HH:mm") === a.time &&
	// 					a.amount++;
	// 			});
	// 		});

	// 		return aux;
	// 	});
	// }, [tickets]);

	return (
		<React.Fragment>
			<Title>{`${i18n.t("dashboard.charts.perMonth.title")} de  ${mesAtual} : ${atendimentos}`}</Title>
			<ResponsiveContainer style={{color:"black"}}>
				<BarChart
					data={chartData}
					barSize={40}
					width={730}
					height={250}
					margin={{
						top: 16,
						right: 16,
						bottom: 0,
						left: 24,
					}}
				>
					<CartesianGrid strokeDasharray="3 3" />
					<XAxis dataKey="time" stroke={theme.palette.text.secondary} />
					<YAxis
						type="number"
						allowDecimals={false}
						stroke={theme.palette.text.secondary}
					>
						<Label
							angle={270}
							position="left"
							style={{ textAnchor: "middle", fill: theme.palette.text.primary }}
						>
							Tickets
						</Label>
					</YAxis>
					<Bar dataKey="amount" fill={theme.palette.primary.main} />
				</BarChart>
			</ResponsiveContainer>
		</React.Fragment>
	);
};

export default Chart;
