import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import FormHelperText from "@material-ui/core/FormHelperText";
import Typography from "@material-ui/core/Typography";
import DataFiltro from "./Datafiltro";
import { provedorteste, logo64 , mensalidade, qtdAtd, exced, inicio, fim , desconto , temProvisao } from "./constants"
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Chart3 from "./Chart3";
import Chart4 from "./Chart4";
import { AuthContext } from "../../context/Auth/AuthContext";
// ICONS

import CallIcon from "@material-ui/icons/Call";
import TimerIcon from '@material-ui/icons/Timer';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import ChatOutlinedIcon from '@material-ui/icons/ChatOutlined';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import { makeStyles } from "@material-ui/core/styles";
import { grey, blue } from "@material-ui/core/colors";
import { toast, ToastContainer } from "react-toastify";

import Chart from "./Chart";

import ButtonWithSpinner from "../../components/ButtonWithSpinner";


import TableAttendantsStatus from "../../components/Dashboard/TableAttendantsStatus";
import { findLast, head, isArray, transform } from "lodash";

import useDashboard  from "../../hooks/useDashboard";
import useCompanies from "../../hooks/useCompanies";

import { isEmpty } from "lodash";
import moment from "moment";
import { endpoint } from '../../App';



export const useStyles = makeStyles((theme) => ({
  botao: {
    border: '1px solid #F2E5FF', // Define uma borda transparente para todos os lados
    //borderImage: 'linear-gradient(to right, #6c4594, #6c4594, #f18a15) 1', 
    
    background: 'linear-gradient(to right, #5D2894, #6c4594,#765698)',
    filter: 'brightness(1. 1) contrast(1.1)',
    boxShadow: '2px 2px 2px 2px rgba(0, 0, 0, 0.3)',
    outline: '1px solid #F2E5FF',
    color: '#fff',
		
	
  },
  ocultar: {
    display: 'none'
  },
  texto:{
    color: theme.palette.text
  },
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
   
  },
  fixedHeightPaper: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    height: 400,
    overflowY: "auto",
    ...theme.scrollbarStyles,
    backgroundColor:  theme.palette.barBackground,
  },
  fixedHeightPaper2: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    height: 800,
    overflowY: "auto",
    ...theme.scrollbarStyles,
    backgroundColor: theme.palette.chartBackground,
  },
  cardAvatar: {
    fontSize: "55px",
    color: grey[500],
    backgroundColor: "#ffffff",
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  cardTitle: {
    fontSize: "18px",
    color: blue[700],
  },
  cardSubtitle: {
    color: grey[600],
    fontSize: "14px",
  },
  alignRight: {
    textAlign: "right",
  },
  fullWidth: {
    width: "100%",

  },
  selectContainer: {
    width: "100%",
    textAlign: "left",
  },
  abaixarNum: {
    textAlign:"end"
  },
  card1: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: "100%",
    //backgroundColor: "#6D30EF",
    backgroundColor: '#6c4594',
    color: "#eee",
    
  },
  card2: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: "100%",
    //backgroundColor: "#6D30EF",
    backgroundColor: '#6c4594',
    color: "#eee",
    
  },
  card3: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: "100%",
    //backgroundColor: "#6D30EF",
    backgroundColor: '#6c4594',
    color: "#eee",
    
  },
  card4: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: "100%",
    //backgroundColor: "#6D30EF",
    backgroundColor: '#5B278F',
    color: "#eee",
    border: '4px solid #F1EAEA', // Define uma borda transparente para todos os lados
    //borderImage: 'linear-gradient(to right, #6c4594, #6c4594, #f18a15) 1', 
    borderRadius: "14px",
    background: 'linear-gradient(45deg, #8D5674 30%,#765698 90%)',
    filter: 'brightness(1. 1) contrast(1.1)',
    boxShadow: '5px 6px 0px rgba(0, 0, 0, 0.3)',
    alignItems: 'center',
    justifyContent:'center',
    textAlign: 'center',

  },
  card5: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: "100%",
    //backgroundColor: "#6D30EF",
    backgroundColor: '#6c4594',
    color: "#eee",
    
  },
  card6: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: "100%",
    //backgroundColor: "#6D30EF",
    backgroundColor: '#6c4594',
    color: "#eee",
    
  },
  card7: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: "100%",
    //backgroundColor: "#6D30EF",
    backgroundColor: '#6c4594',
    color: "#eee",
    
  },
  card8: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: "100%",
    //backgroundColor: "#6D30EF",
    backgroundColor: '#6c4594',
    color: "#eee",
    
  },
  card9: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: "100%",
    //backgroundColor: "#6D30EF",
    backgroundColor: '#6c4594',
    color: "#eee",
    
  },
  select: {
    bbackgroundColor:  "#301E5E",
    color: theme.palette.primary
  },
  titulo: {
    color: theme.palette.primary.main
  }
}));

const Dashboard = () => {
  const classes = useStyles();
  const { user } = useContext(AuthContext); 
  const [company, setCompany] = useState(null);
  const [counters, setCounters] = useState({});
  const [attendants, setAttendants] = useState([]);
  const [filterType, setFilterType] = useState(1);
  const [period, setPeriod] = useState(0);
  const [companyDueDate, setCompanyDueDate] = useState();
  const [dateFrom, setDateFrom] = useState(
    moment("1", "D").format("YYYY-MM-DD")
  );
  const [dateTo, setDateTo] = useState(moment().format("YYYY-MM-DD"));
  const [loading, setLoading] = useState(false);
  const { find } = useDashboard();
  const { finding } = useCompanies();
  
  useEffect(() => {
    if (user && user.company) {
      setCompany(user.company.name); 
    }
  }, [user]);
  const nomedaempresa = company


  useEffect(() => {
    async function firstLoad() {
      await fetchData();
    }
    setTimeout(() => {
      firstLoad();
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  
  
  async function handleChangeFilterType(value) {
    setFilterType(value);
    if (value === 1) {
      setPeriod(0);
    } else {
      setDateFrom("");
      setDateTo("");
    }
  
  }

  async function handleChangePeriod(value) {
    setPeriod(value);
  
    const endDate = moment().format("YYYY-MM-DD");
    const startDate = moment().subtract(value, 'days').format("YYYY-MM-DD");
    setDateFrom(startDate);
    setDateTo(endDate);
  }

  async function fetchData() {
    setLoading(true);

    let params = {};

    if (period > 0) {
      params = {
        days: period,
      };
    }

    if (!isEmpty(dateFrom) && moment(dateFrom).isValid()) {
      params = {
        ...params,
        date_from: moment(dateFrom).format("YYYY-MM-DD"),
      };
    }

    if (!isEmpty(dateTo) && moment(dateTo).isValid()) {
      params = {
        ...params,
        date_to: moment(dateTo).format("YYYY-MM-DD"),
      };
    }

    if (Object.keys(params).length === 0) {
      toast.error("Parametrize o filtro");
      setLoading(false);
      return;
    }

    const data = await find(params);
    //console.log(data)
    
    setCounters(data.counters);
    if (isArray(data.attendants)) {
      setAttendants(data.attendants);
    } else {
      setAttendants([]);
    }

    setLoading(false);
  }

 
  //(dateFrom) // Data do primeiro filtro
  // (dateTo) // Data do segundo filtro

  
  const [response, setResponse] = useState()
  const [error,setError] = useState()

  //Intervalo que usei q funcionou pra sincronizar os dados diarios do grafico com os cards
  // "DataHora_InicioAtend_ISO": {
  //   "$gte": dateFrom + "T03:00:00:000Z",
  //   "$lte": dateTo + "T02:59:59.999Z"
  // }
  
  async function queryFiltro() {
    toast.info('Carregando dados...', {
      toastId: 'loading',
      autoClose: false,
      closeButton: false,
      progress: undefined
    });
    
    try {
      const [resTotais, resTelefone, resChat, resMas] = await Promise.all([
        axios.post(endpoint + "/query", {
          "query": {
            "DataHora_InicioAtend_ISO": {
              "$gte": dateFrom + "T00:00:00.000Z",
              "$lte": dateTo + "T23:59:59.999Z"
            },
            "tipo_atendimento": { "$in": ["CHAT", "LIGAÇÃO"] },
            "Provedor": nomedaempresa
          }
        }, {
          headers: { 'Content-Type': 'application/json; charset=utf-8' }
        }),
        axios.post(endpoint + "/query", {
          "query": { 
            "DataHora_InicioAtend_ISO": {
              "$gte": dateFrom + "T00:00:00.000Z",
              "$lte": dateTo + "T23:59:59.999Z"
            },
            "tipo_atendimento": "LIGAÇÃO",
            "Provedor": nomedaempresa
          }
        }, {
          headers: { 'Content-Type': 'application/json; charset=utf-8' }
        }),
        axios.post(endpoint + "/query", {
          "query": { 
            "DataHora_InicioAtend_ISO": {
              "$gte": dateFrom + "T00:00:00.000Z",
              "$lte": dateTo + "T23:59:59.999Z"
            },
            "tipo_atendimento": "CHAT",
            "Provedor": nomedaempresa
          }
        }, {
          headers: { 'Content-Type': 'application/json; charset=utf-8' }
        }),
        axios.post(endpoint + "/query", {
          "query": {
            "DataHora_InicioAtend_ISO": {
              "$gte": dateFrom + "T00:00:00.000Z",
              "$lte": dateTo + "T23:59:59.999Z"
            },
            "Diagnostico_Finalizacao": { "$regex": "massiv", "$options": "i" },
            "Provedor": nomedaempresa
          }
        }, {
          headers: { 'Content-Type': 'application/json; charset=utf-8' }
        })
      ]);
  
      const novosDados = {
        dadostotais: resTotais.data.length,
        dadostelefone: resTelefone.data.length,
        dadoschat: resChat.data.length,
        dadossmassiva: resMas.data.length
      };
  
      setDados(novosDados);
      setDadosOriginais(novosDados);
      setShowDataFiltro(true); 
  
      toast.dismiss('loading');
      toast.success('Dados carregados com sucesso!');
    } catch (error) {
      console.error(error)
      console.error('Erro ao enviar as queries:', error);
      alert('Ocorreu um erro ao tentar enviar as consultas. Verifique o console para mais detalhes.');
    }
  }
  
const [numatd, setNumatd] = useState(0);
const [valorfinal, setValorfinal] = useState(mensalidade);
const [valorporatd,setValorporatd] = useState(0)
const  [descontopro, setDescontopro] = useState(0)
//Tem que fazer o desconto Pro-Rata , que é 
//Desconto pró rata
const datainicio = new Date("2022-08-04")
const fimprovisao = new Date("2024-08-31")

useEffect(() => {
  // Calcular desconto pro-rata
  let res = 0;
  if (temProvisao == true) {
    const x = mensalidade / 30;
    const z = (datainicio - fim) / (1000 * 60 * 60 * 24); // Diferença em dias
    const y = (fimprovisao - fim) / (1000 * 60 * 60 * 24); // Diferença em dias
    if (y < 30) {
      res = y * x;
    } else if (z < 30) {
      res = (30 - z) * x;
    }
    setDescontopro(res)
  } else {
    setDescontopro(res)
  }
  //console.log(descontopro)
}, [mensalidade, datainicio, fimprovisao, fim]);

useEffect(() => {
  const fetchData = async () => {
    try {
      const response = await axios.post(
        endpoint + "/query",
        {
          query: {
            DataHora_InicioAtend_ISO: {
              $gte: `${inicio}T00:00:00.000Z`,
              $lte: `${fim}T23:59:59.999Z`
            },
            Provedor: nomedaempresa
          }
        },
        {
          headers: { 'Content-Type': 'application/json; charset=utf-8' }
        }
      );

      const data = response.data;
      setNumatd(data.length);

      // Calcular valor final com o desconto pro-rata
      let valor = mensalidade - desconto - descontopro;
      if (data.length > qtdAtd) {
        valor += ((data.length - qtdAtd) * exced);
      }

      setValorfinal(valor);
      setValorporatd(qtdAtd > 0 ? mensalidade / qtdAtd : 0);
    } catch (error) {
      console.error('Erro ao buscar dados:', error);
    }
  };

  fetchData();
}, [inicio, fim, nomedaempresa, mensalidade, desconto, descontopro]); // Inclua dependências relevantes

  // useEffect(()=>{
  //   if( numatd > qtdAtd) {
  //     const valor = ((numatd - qtdAtd)*exced) + mensalidade
  //     setValorfinal(valor)
  //   } else {
  //     setValorfinal(mensalidade)  
  //   }
  // } , [numatd])
  
//Funções que vão colocar os dados apos a requisição no endpoint
const [dados, setDados] = useState({
  dadostotais: 0,
  dadostelefone: 0,
  dadoschat: 0,
  dadossmassiva: 0
});
const [dadosOriginais, setDadosOriginais] = useState(dados);
const [showDataFiltro, setShowDataFiltro] = useState(false)

//Função para atualizar o estado
const atualizarEstado = (tipo, valor) => {
  setDados(prevDados => ({
    ...prevDados,
    [tipo]: valor
  }));
};


// Função para enviar todas as queries
  const enviarQueries = async () => {
    if (!nomedaempresa) return; 

     // Exibir notificação de carregamento
     toast.info('Carregando dados...', {
      toastId: 'loading',
      autoClose: false,
      closeButton: false,
      progress: undefined
    });
    try {
      
      const [resTotais, resTelefone, resChat, resMas] = await Promise.all([
        axios.post(endpoint + "/query", {
          "query": {
            "tipo_atendimento": {
              "$in": ["CHAT", "LIGAÇÃO"]
            },
            "Provedor": nomedaempresa
          }
        }, {
          headers: { 'Content-Type': 'application/json; charset=utf-8' }
        }),
        axios.post(endpoint + "/query", {
          "query": { "tipo_atendimento": "LIGAÇÃO" ,  "Provedor": nomedaempresa}
        }, {
          headers: { 'Content-Type': 'application/json; charset=utf-8' }
        }),
        axios.post(endpoint + "/query", {
          "query": { "tipo_atendimento": "CHAT",  "Provedor": nomedaempresa }
        }, {
          headers: { 'Content-Type': 'application/json; charset=utf-8' }
        }),
        axios.post(endpoint + "/query", {
          "query": {
            "Diagnostico_Finalizacao": {
              "$regex": "massiv",
              "$options": "i"
            },
            "Provedor": nomedaempresa
          }
        }, {
          headers: { 'Content-Type': 'application/json; charset=utf-8' }
        })
      ]);

      // Atualizando os estados com os dados das respostas
      atualizarEstado('dadostotais', resTotais.data.length);
      atualizarEstado('dadostelefone', resTelefone.data.length);
      atualizarEstado('dadoschat', resChat.data.length);
      atualizarEstado('dadossmassiva', resMas.data.length);

       // Ocultar notificação de carregamento
       toast.dismiss('loading');
       toast.success('Dados carregados com sucesso!');
    } catch (error) {
      console.error('Erro ao enviar as queries:', error);
      // Adicione uma mensagem de erro para visualização
      alert('Ocorreu um erro ao tentar enviar as consultas. Verifique o console para mais detalhes.');
    }
  };

 
  useEffect(() => {
    enviarQueries();
  }, [nomedaempresa]);

  const restaurarDados = () => {
    enviarQueries(); 
    setShowDataFiltro(false); 
  };


const [data, setData] = useState(null);
const [load, setLoad] = useState(true);
const [erros, setErros] = useState(null);

  

  useEffect(() => {
    async function fetchData() {
      await loadCompanies();
    }
    fetchData();
  }, [])
  //let companyDueDate = localStorage.getItem("companyDueDate");
  //const companyDueDate = localStorage.getItem("companyDueDate").toString();
  const companyId = localStorage.getItem("companyId");
  const loadCompanies = async () => {
    setLoading(true);
    try {
      const companiesList = await finding(companyId);
      setCompanyDueDate(moment(companiesList.dueDate).format("DD/MM/yyyy"));
    } catch (e) {
      console.log("🚀 Console Log : e", e);
      // toast.error("Não foi possível carregar a lista de registros");
    }
    setLoading(false);
  };

  function formatTime(minutes) {
    return moment()
      .startOf("day")
      .add(minutes, "minutes")
      .format("HH[h] mm[m]");
  }
  
  const gerarpdf = () => {
    
    const doc = new jsPDF()
    doc.addImage(logo64, 'PNG', 10, 10, 50, 20);
    
    const nomeProvedor = nomedaempresa
    const data1 = dateFrom
    const data2 = dateTo
    doc.setFontSize(14); // Define o tamanho da fonte para o parágrafo
    doc.text("Provedor:" + nomeProvedor, 14, 40); 
    doc.text("Periodo:"+ data1 + "até" + data2, 14,50)
    const colunas = ["Tipo" , "Total"]
    const data = [
      ["Quantidade de Atendimento Totais", dados.dadostotais.toString()],
      ["Quantidade de Atendimento Massiva", dados.dadossmassiva.toString()],
      ["Quantidade de Atendimento Chat", dados.dadoschat.toString()],
      ["Quantidade de Atendimento Telefone", dados.dadostelefone.toString()],
    ];
  
   
    doc.autoTable({
      head: [colunas],
      body: data,
      startY: 45, // Ajusta a posição vertical da tabela
      theme: 'grid', // Opcional: usa um tema de listras
      headStyles: { 
        fillColor: [108, 69, 148],
        textColor: [255, 255, 255]
       }, 
      styles: { 
        fontSize: 12, 
        textColor: [0, 0, 0], // Cor do texto das células (preto, por exemplo)
      lineColor: [0, 0, 0], 
      }, // Tamanho da fonte
      margin: { top: 10 }, // Margem superior
      pageBreak: 'auto'
    });

    doc.save('relatorio.pdf')
  }
 
  


  function renderFilters() {
    if (filterType === 1) {
      return (
        <>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              label="Data Inicial"
              type="date"
              value={dateFrom}
              onChange={(e) => setDateFrom(e.target.value)}
              className={classes.fullWidth}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              label="Data Final"
              type="date"
              value={dateTo}
              onChange={(e) => setDateTo(e.target.value)}
              className={classes.fullWidth}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </>
      );

    } 
    else {
      return (
        <Grid item xs={12} sm={6} md={4}>
         
          <FormControl className={classes.selectContainer}>
            <InputLabel id="period-selector-label">Período</InputLabel>
            <Select
              labelId="period-selector-label"
              id="period-selector"
              value={period}
              onChange={(e) => handleChangePeriod(e.target.value)}
            >
              <MenuItem value={0}>Nenhum selecionado</MenuItem>
              <MenuItem value={3}>Últimos 3 dias</MenuItem>
              <MenuItem value={7}>Últimos 7 dias</MenuItem>
              <MenuItem value={15}>Últimos 15 dias</MenuItem>
              <MenuItem value={30}>Últimos 30 dias</MenuItem>
              <MenuItem value={60}>Últimos 60 dias</MenuItem>
              <MenuItem value={90}>Últimos 90 dias</MenuItem>
            </Select>
            <FormHelperText>Selecione o período desejado</FormHelperText>
          </FormControl>
        </Grid>
      );
    }
  }

  return (
    <div>
      <Container maxWidth="lg" className={classes.container}>
      <ToastContainer />
      <h1 className={classes.titulo}>Dados : {nomedaempresa}</h1>
      {showDataFiltro && <DataFiltro dateFrom={dateFrom} dateTo={dateTo} className={classes.titulo} />}
     
        <Grid container spacing={3} justifyContent="flex-end">
          
          {/* GRID DO VENCIMENTO */}
          {/* <Grid item xs={12} sm={6} md={3}>
            <CardCounter
              icon={<TodayIcon fontSize="inherit" />}
              title="Data Vencimento"
              value={companyDueDate}
              loading={loading}
            />
          </Grid> */}

          {/* ATENDIMENTOS REALIZADOS */}
          <Grid item xs={10} sm={4} md={3}>
            <Paper
              className={classes.card4}
              style={{ overflow: "hidden" }}
              
            >
              <Grid container spacing={3}>
                <Grid item xs={8}>
                  <Grid item>
                    <Typography
                      component="h1"
                      variant="h4"
                    >
                      {dados.dadostotais}
                    </Typography>
                  </Grid>
                  <Typography
                    component="p"
                    variant="body2"
                    paragraph
                  >
                   Quantidade de Atendimento Totais
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <CheckOutlinedIcon
                    style={{
                      fontSize: 40,
                      color: "#FFFFFF",
                    }}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          {/* Atendimentos sem Massiva */}
          <Grid item xs={10} sm={4} md={3}>
            <Paper
              className={classes.card4}
              style={{ overflow: "hidden" }}
              elevation={6}
            >
              <Grid container spacing={3}>
                <Grid item xs={8}>
                 
                  <Grid item>
                    <Typography
                      component="h1"
                      variant="h4"
                    >
                      {dados.dadossmassiva}
                    </Typography>
                  </Grid>
                  <Typography
                    component="p"
                    variant="body2"
                    paragraph
                  >
                  Quantidade Atendimentos Massiva
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <CheckOutlinedIcon
                    style={{
                      fontSize: 40,
                      color: "#FFFFFF",
                    }}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
           {/* Atendimentos Chat */}
          <Grid item xs={10} sm={4} md={3}>
            <Paper
              className={classes.card4}
              style={{ overflow: "hidden" }}
              elevation={6}
            >
              <Grid container spacing={3}>
                <Grid item xs={8}>
                  
                  <Grid item>
                    <Typography
                      component="h1"
                      variant="h4"
                    >
                       {dados.dadoschat}
                    </Typography>
                  </Grid>
                  <Typography
                    component="p"
                    variant="body2"
                    paragraph
                  >
                   Quantidade Atendimentos Chat
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <ChatOutlinedIcon
                    style={{
                      fontSize: 40,
                      color: "#FFFFFF",
                    }}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
           {/* Qtd Atendimentos Telefone */}
          <Grid item xs={10} sm={4} md={3}>
            <Paper
              className={classes.card4}
              style={{ overflow: "hidden" }}
              elevation={6}
            >
              <Grid container spacing={3}>
                <Grid item xs={8}>
                 
                  <Grid item>
                    <Typography
                      component="h1"
                      variant="h4"
                    >
                       {dados.dadostelefone}
                    </Typography>
                  </Grid>
                  <Typography
                    component="p"
                    variant="body2"
                    paragraph
                  >
                   Quantidade Atendimentos Telefone
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <CallIcon
                    style={{
                      fontSize: 40,
                      color: "#FFFFFF",
                    }}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>

       
           
            <div className={classes.ocultar}>
          {/* T.M. DE ESPERA Chat */}
          <Grid item xs={10} sm={4} md={3}>
            <Paper
              className={classes.card4}
              style={{ overflow: "hidden" }}
              elevation={6}
            >
              <Grid container spacing={3}>
                <Grid item xs={8}>
                  
                  <Grid item>
                    <Typography
                      component="h1"
                      variant="h4"
                    >
                      {formatTime(counters.avgWaitTime)}
                    </Typography>
                  </Grid>
                  <Typography
                    component="p"
                    variant="body2"
                    paragraph
                  >
                    TME - Chat
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <TimerIcon
                    style={{
                      fontSize: 40,
                      color: "#FFFFFF",
                    }}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          
          {/* T.M. DE ESPERA Telefone */}
          <Grid item xs={10} sm={4} md={3}>
            <Paper
              className={classes.card4}
              style={{ overflow: "hidden" }}
              elevation={6}
            >
              <Grid container spacing={3}>
                <Grid item xs={8}>
                 
                  <Grid item>
                    <Typography
                      component="h1"
                      variant="h4"
                    >
                      {formatTime(counters.avgWaitTime)}
                    </Typography>
                  </Grid>
                  <Typography
                    component="p"
                    variant="body2"
                    paragraph
                  >
                    TME - Telefone
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <TimerIcon
                    style={{
                      fontSize: 40,
                      color: "#FFFFFF",
                    }}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid> 

          {/* T.M. DE ATENDIMENTO Telefone*/}
          <Grid item xs={10} sm={4} md={3}>
            <Paper
              className={classes.card4}
              style={{ overflow: "hidden" }}
              elevation={6}
            >
              <Grid container spacing={3}>
                <Grid item xs={8}>
                  
                  <Grid item>
                    <Typography
                      component="h1"
                      variant="h4"
                    >
                      {formatTime(counters.avgSupportTime)}
                    </Typography>
                  </Grid>
                  <Typography
                    component="p"
                    variant="body2"
                    paragraph
                  >
                    TMA - Telefone
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <TimerIcon
                    style={{
                      fontSize: 40,
                      color: "#FFFFFF",
                    }}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          
           {/* T.T.A Telefone */}
          <Grid item xs={10} sm={4} md={3}>
            <Paper
              className={classes.card4}
              style={{ overflow: "hidden" }}
              elevation={6}
            >
              <Grid container spacing={3}>
                <Grid item xs={8}>
            
                  <Grid item>
                    <Typography
                      component="h1"
                      variant="h4"
                    >
                      {formatTime(counters.avgWaitTime)}
                    </Typography>
                    <Typography
                    component="p"
                    variant="body2"
                    paragraph
                  >
                    TTA - Telefone
                  </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <TimerIcon
                    style={{
                      fontSize: 40,
                      color: "#FFFFFF",
                    }}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid> 
          </div>
          {/* BOTAO GERAR RELATORIO */}
        
          <Grid item xs={12} className={classes.alignRight}>
              <ButtonWithSpinner
                variant="contained"
                className={classes.botao}
                onClick={gerarpdf}
              >
                Gerar Relatorio
              </ButtonWithSpinner>
          </Grid> 

          
       

           <Grid item xs={12} sm={6} md={4}>
            <FormControl className={classes.selectContainer}>
            
              <InputLabel id="period-selector-label">Tipo de Filtro</InputLabel>
              <Select
                labelId="period-selector-label"
                value={filterType}
                onChange={(e) => handleChangeFilterType(e.target.value)}
              >
                <MenuItem value={1}>Filtro por Data</MenuItem>
                <MenuItem value={2}>Filtro por Período</MenuItem>
              </Select>
              <FormHelperText>Selecione o período desejado</FormHelperText>
            </FormControl>
          </Grid> 

          
          {renderFilters()}

          {/*
          100vh
          
          */}
          
          {/* BOTAO FILTRAR */}
          <Grid item xs={12} className={classes.alignRight}>
            <ButtonWithSpinner
              loading={loading}
              onClick={() => queryFiltro()}
              variant="contained"
              className={classes.botao}
            >
              Filtrar
            </ButtonWithSpinner>
          </Grid>
          <Grid item xs={12} className={classes.alignRight}>
            <ButtonWithSpinner
              loading={loading}
              onClick={() => restaurarDados()}
              variant="contained"
              className={classes.botao}
            >
              Restaurar Dados
            </ButtonWithSpinner>
          </Grid>

         

          {/* DASHBOARD ATENDIMENTOS HOJE */}
          <Grid item xs={12}>
            <Paper className={classes.fixedHeightPaper}>
              <Chart />
            </Paper>
          </Grid>

          {/* USUARIOS ONLINE */}
          <Grid item xs={12}>
            {attendants.length ? (
              <TableAttendantsStatus
                attendants={attendants}
                loading={loading}
              />
            ) : null}
          </Grid>

          {/* DASHBOARD DIAGNOSTICOS */}
          {showDataFiltro == false && <Grid item xs={12}>
            <Paper className={classes.fixedHeightPaper2}>
              <Chart3/>
            </Paper>
          </Grid>}
          {showDataFiltro == true && <Grid item xs={12}>
            <Paper className={classes.fixedHeightPaper2}>
              <Chart4 dateFrom={dateFrom} dateTo={dateTo}/>
            </Paper>
          </Grid>}
          

{/*           
          <Grid item xs={10} sm={4} md={3}>
            <Paper
              className={classes.card4}
              style={{ overflow: "hidden" }}
              elevation={6}
            >
              <Grid container spacing={3}>
                <Grid item xs={8}>
                  <Typography
                    component="h3"
                    variant="h6"
                    paragraph
                    className={classes.abaixarNum}
                  >
                   Valor Final da Fatura
                  </Typography>
                  <Grid item>
                    <Typography
                      component="h1"
                      variant="h4"
                    >
                     R${valorfinal.toFixed(2)},00
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <MonetizationOnIcon
                    style={{
                      fontSize: 75,
                      color: "#FFFFFF",
                    }}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid> */}

           {/* Valor por Atendimento Unitário
          <Grid item xs={10} sm={4} md={3}>
            <Paper
              className={classes.card4}
              style={{ overflow: "hidden" }}
              elevation={6}
            >
              <Grid container spacing={3}>
                <Grid item xs={8}>
                  <Typography
                    component="h3"
                    variant="h6"
                    paragraph
                  >
                  Valor por Atendimento Unitário 
                  </Typography>
                  <Grid item>
                    <Typography
                      component="h1"
                      variant="h4"
                    >
                     R$ {valorporatd.toFixed(2)},00
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <MonetizationOnIcon
                    style={{
                      fontSize: 75,
                      color: "#FFFFFF",
                    }}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>  */}
        </Grid>    
      </Container>
      
    </div>
  );
};

export default Dashboard;
