import React, { useEffect, useState, useContext } from "react"
import axios from "axios";
import { format, set } from "date-fns";
import { endpoint } from "../../App";
import { AuthContext } from "../../context/Auth/AuthContext";
import ButtonWithSpinner from "../../components/ButtonWithSpinner";
import { useStyles } from "../Dashboard";
import moment from "moment";
import TextField from "@material-ui/core/TextField";

import "./billing.css";

const Billing = () => {

    const provedores_com_faturamento_antigo = [
        "ICLICK",
        "MUNDIAL",
        "NOVELL",
        "OUROMAX",
        "PERFECT",
        "RBONLINE",
        "SEJAFIBRA",
        "SETTE",
        "VMNET",
        "VOE",
        "Upcall1",
        "Upcall",
        "DKIROS2",
        "DKIROS",
        "NETCENTER",
        "DBS",
        "BRASILINK",
        "FAISER",
        "MEGANETS",
        "DELTAATIVA"
    ]

    const currentYear = new Date().getFullYear(); // Ano atual
    const [selectedYear, setSelectedYear] = useState(currentYear);

    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth()); // Estado para armazenar o mês selecionado
    const [error, setError] = useState(''); // Estado para armazenar o erro

    const [dateFrom, setDateFrom] = useState(
        moment().startOf("month").format("YYYY-MM-DD")
    );
    const [dateTo, setDateTo] = useState(moment().format("YYYY-MM-DD"));

    const handleMonthChange = (event) => {
        const value = event.target.value;
        setSelectedMonth(value);

        // Validação simples (exemplo, verifique se o mês foi selecionado)
        if (value === '') {
            setError('Por favor, selecione um mês.');
        } else {
            setError('');
        }
    };

    const handleYearChange = (event) => {
        const value = event.target.value;
        setSelectedYear(value);

        // Validação simples (exemplo, verifique se o ano foi selecionado)
        if (value === '') {
            setError('Por favor, selecione um ano.');
        } else {
            setError('');
        }
    };

    const classes = useStyles();

    const [atendimentos, setAtendimentos] = useState([]);
    const [atendimentosCobrados, setAtendimentosCobrados] = useState([]);
    const [atendimentosContestados, setAtendimentosContestados] = useState([]);
    const [faturamento, setFaturamento] = useState(0);

    const [loading, setLoading] = useState(false);
    const [VPA, setVPA] = useState(0);
    const [VPE, setVPE] = useState(0);
    const [VPATotal, setVPATotal] = useState(0);
    const [VPETotal, setVPETotal] = useState(0);

    const { user } = useContext(AuthContext);

    const [dadosProvedores, setDadosProvedores] = useState([])

    const pageSize = 50;
    const totalPages = Math.ceil(atendimentos.length / pageSize) || 1;

    const [page, setPage] = useState(1);

    const columns = [
        {
            field: 'Atendente',
            headerName: 'Atendente',
        },
        {
            field: 'DataHora_InicioAtend',
            headerName: 'Data/Hora Inicio',
        },
        {
            field: 'tipo_atendimento',
            headerName: 'Tipo Atendimento',
        },
        {
            field: 'nome_cliente',
            headerName: 'Nome Cliente',
        },
        {
            field: 'AtendimentoCobrado',
            headerName: 'Cobrado?',
        },
        {
            field: 'status_atendimento',
            headerName: 'Status Atendimento',
        }
    ];

    const columns_provedores = [{
        field: 'name',
        headerName: 'Provedor'
    },
    {
        field: 'purchasedCalls',
        headerName: 'Atendimentos Comprados'
    },
    {
        field: 'atendimentos',
        headerName: 'Atendimentos'
    },
    {
        field: 'atendimentos_cobrados',
        headerName: 'Atendimentos Cobrados'
    },
    {
        field: 'atendimentos_contestados',
        headerName: 'Atendimentos Contestados'
    },
    {
        field: 'vpa',
        headerName: 'VPA'
    },
    {
        field: 'vpa_total',
        headerName: 'VPA Total'
    },
    {
        field: 'vpe',
        headerName: 'VPE'
    },
    {
        field: 'vpe_total',
        headerName: 'VPE Total'
    },
    {
        field: "fixedcallvalue",
        headerName: "Valor Fixo (Min)"
    },
    {
        field: 'faturamento',
        headerName: 'Faturamento'
    }
    ]

    const startIndex = (page - 1) * pageSize;
    const paginatedAtendimentos = atendimentos.slice(startIndex, startIndex + pageSize);

    const getBillingData = async () => {
        setLoading(true);
        let primeiroDiaDoMes = format(new Date(selectedYear, selectedMonth, 1), 'yyyy-MM-dd');
        let ultimoDiaDoMes = format(new Date(selectedYear, selectedMonth + 1, 0), 'yyyy-MM-dd');
        if (provedores_com_faturamento_antigo.includes(user.company.name.toUpperCase())) {
            primeiroDiaDoMes = format(new Date(selectedYear, selectedMonth - 1, 26), 'yyyy-MM-dd');
            ultimoDiaDoMes = format(new Date(selectedYear, selectedMonth, 25), 'yyyy-MM-dd');
        }
        console.log(primeiroDiaDoMes, ultimoDiaDoMes)
        const response = await axios.post(endpoint + "/provedor/periodo", {
            DataInicio: primeiroDiaDoMes,
            DataFim: ultimoDiaDoMes,
            Provedor: user.company.name
        }, {
            headers: { 'Content-Type': 'application/json; charset=utf-8' }
        });
        const { fixedcallvalue, excesscallvalue, purchasedCalls } = response.data;
        const atendimentos_response = response.data.atendimentos
        const atendimentos_cobrados = atendimentos_response.filter(atendimento => atendimento.AtendimentoCobrado === "S")
        const atendimentos_contestados = atendimentos_response.filter(atendimento => atendimento.AtendimentoCobrado === "N")
        setAtendimentos(atendimentos_response)
        const valuepercall = (fixedcallvalue / purchasedCalls).toFixed(2)
        const custo = atendimentos_cobrados > Number(purchasedCalls) ?
            purchasedCalls * valuepercall + (atendimentos_cobrados.length - purchasedCalls) * excesscallvalue :
            atendimentos_cobrados.length * valuepercall
        setVPA(valuepercall)
        setVPE(excesscallvalue)
        setVPETotal(Number(excesscallvalue) * (Number(atendimentos_cobrados.length) - Number(purchasedCalls)))
        setVPATotal(Number(custo))

        setAtendimentosCobrados(atendimentos_cobrados)
        setAtendimentosContestados(atendimentos_contestados)
        if (Number(custo) < Number(fixedcallvalue)) {
            setFaturamento(Number(fixedcallvalue))
        } else {
            setFaturamento(Number(custo))
        }
        setLoading(false)
        console.log(user)
    }

    const getAllProvidersBilling = async () => {
        setLoading(true);
        const response = await axios.post(endpoint + "/todos-provedores", {
            DataInicio: dateFrom,
            DataFim: dateTo,
        }, {
            headers: { 'Content-Type': 'application/json; charset=utf-8' }
        });
        console.log(response.data)
        const companies_values = response.data.companies_values
        const atendimentos = response.data.atendimentos
        const dados_provedores = companies_values.map((value) => {
            let vpa = value.purchasedCalls == 0 || !value.fixedcallvalue ? 0 : (value.fixedcallvalue / value.purchasedCalls).toFixed(2)

            return {
                "name": value.name,
                "vpa": vpa,
                "vpe": value.excesscallvalue,
                "purchasedCalls": value.purchasedCalls,
                "fixedcallvalue": value.fixedcallvalue,
                "vpe_total": 0,
                "vpa_total": 0,
                "atendimentos": 0,
                "atendimentos_cobrados": 0,
                "atendimentos_contestados": 0,
                "faturamento": 0
            }
        })
        console.log(dados_provedores)
        for (let i = 0; i < atendimentos.length; i++) {
            const { Provedor, AtendimentoCobrado } = atendimentos[i]
            const index = dados_provedores.findIndex((value) => value.name === Provedor)
            if (index == -1) continue
            dados_provedores[index].atendimentos += 1
            if (AtendimentoCobrado === "S") {
                dados_provedores[index].atendimentos_cobrados += 1
            }
            if (AtendimentoCobrado === "N") {
                dados_provedores[index].atendimentos_contestados += 1
            }
        }

        for (let i = 0; i < dados_provedores.length; i++) {
            const current = dados_provedores[i]
            const { atendimentos, atendimentos_cobrados, vpa, vpe, purchasedCalls, fixedcallvalue } = current
            current.faturamento = atendimentos_cobrados > purchasedCalls ? (purchasedCalls * vpa) + ((atendimentos_cobrados - purchasedCalls) * vpe) : atendimentos_cobrados * vpa
            if (current.faturamento < fixedcallvalue) {
                current.faturamento = fixedcallvalue
            }
            current.vpe_total = (atendimentos - purchasedCalls) * vpe
            if (current.vpe_total < 0) {
                current.vpe_total = 0
            }
            current.vpa_total = atendimentos_cobrados * vpa
        }
        setLoading(false)
        setDadosProvedores(dados_provedores)
    }


    useEffect(() => {
        if (user.company.name.toUpperCase() != "UPCALL") {
            getBillingData()
        }
    }, [])

    const paginationButtonStyle = {
        backgroundColor: "#007BFF",
        color: "#fff",
        border: "none",
        borderRadius: "4px",
        padding: "8px 16px",
        cursor: "pointer",
        transition: "background-color 0.3s",
        fontSize: "14px",
    };

    paginationButtonStyle[":hover"] = {
        backgroundColor: "#0056b3", // Darker blue when hovered
    };
    return (

        (<div style={{ width: "100%", padding: "20px", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
            <h1 style={{ textAlign: "center" }}>Faturamento</h1>
            <div style={{ display: "flex", width: "100%", gap: "1em", alignItems: "center" }}>

                {
                    user.company.name.toUpperCase() == "UPCALL" ?
                    <div style={{ display: "flex", gap: "1em", width: "600px", alignItems: "center", marginBottom: "20px" }}>
                        <TextField
                            label="Data Inicial"
                            type="date"
                            value={dateFrom}
                            onChange={(e) => setDateFrom(e.target.value)}
                            className={classes.fullWidth}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />

                        <TextField
                            label="Data Final"
                            type="date"
                            value={dateTo}
                            onChange={(e) => setDateTo(e.target.value)}
                            className={classes.fullWidth}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </div>
                        :
                        (
                            <div  style={{ display: "flex", gap: "1em", alignItems: "center" }}>
                                <div className="form-control">
                                    <label htmlFor="year" className="label">Ano</label>
                                    <select
                                        id="year"
                                        name="year"
                                        value={selectedYear}
                                        onChange={handleYearChange}
                                        className={`select ${error ? 'error' : ''}`}
                                    >
                                        <option value="">Selecione o ano</option>
                                        {/* Gera opções para o ano atual e os 3 anos anteriores */}
                                        {[...Array(4)].map((_, index) => {
                                            const yearOption = currentYear - index;
                                            return (
                                                <option key={yearOption} value={yearOption}>
                                                    {yearOption}
                                                </option>
                                            );
                                        })}
                                    </select>
                                    {error && <div className="error-text">{error}</div>}
                                </div>

                                <div className="form-control">
                                    <label htmlFor="priority" className="label">Mês</label>
                                    <select
                                        id="priority"
                                        name="priority"
                                        value={selectedMonth}
                                        onChange={handleMonthChange}
                                        className={`select ${error ? 'error' : ''}`}
                                    >
                                        <option value={0}>Janeiro</option>
                                        <option value={1}>Fevereiro</option>
                                        <option value={2}>Março</option>
                                        <option value={3}>Abril</option>
                                        <option value={4}>Maio</option>
                                        <option value={5}>Junho</option>
                                        <option value={6}>Julho</option>
                                        <option value={7}>Agosto</option>
                                        <option value={8}>Setembro</option>
                                        <option value={9}>Outubro</option>
                                        <option value={10}>Novembro</option>
                                        <option value={11}>Dezembro</option>
                                    </select>
                                    {error && <div className="error-text">{error}</div>}
                                </div>
                            </div>
                        )

                }

                <ButtonWithSpinner
                    loading={loading}
                    onClick={() => user.company.name.toUpperCase() == "UPCALL"? getAllProvidersBilling() : getBillingData()}
                    variant="contained"
                    className={classes.botao}
                >
                    Filtrar
                </ButtonWithSpinner>
            </div>
            {
                user.company.name.toUpperCase() == "UPCALL"?
                    <>
                        {
                            dadosProvedores.length > 0 && (
                                <div style={{ width: "100%" }}>
                                    <div style={{ backgroundColor: "#fff", borderRadius: "8px", boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", padding: "20px", overflow: "hidden" }}>

                                        {dadosProvedores.length > 0 && (
                                            <div>
                                                <table style={{ width: "100%", borderCollapse: "collapse" }}>
                                                    <thead>
                                                        <tr>
                                                            {columns_provedores.map((column) => (
                                                                <th key={column.field} style={{
                                                                    padding: "10px",
                                                                    textAlign: "center",
                                                                    backgroundColor: "#f4f4f4",
                                                                    borderBottom: "2px solid #e0e0e0",
                                                                    fontWeight: "bold"
                                                                }}>
                                                                    {column.headerName}
                                                                </th>
                                                            ))}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {dadosProvedores.map((atendimento) => (
                                                            <tr key={atendimento._id}>
                                                                {columns_provedores.map((column, index) => (
                                                                    <td key={atendimento._id + index.toString()} style={{
                                                                        textAlign: "center",
                                                                        padding: "10px",
                                                                        borderBottom: "1px solid #e0e0e0"
                                                                    }}>
                                                                        {atendimento[column.field] ?
                                                                            (["vpa", "vpe", "fixedcallvalue", "faturamento", "vpe_total", "vpa_total"].includes(column.field) ? `R$ ${atendimento[column.field]}` : atendimento[column.field]) :
                                                                            (atendimento[column.field] == 0 ? "0" : "-")}
                                                                    </td>
                                                                ))}
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>

                                                {/* Pagination */}
                                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "20px" }}>
                                                    {/* Previous Button */}
                                                    {page > 1 && (
                                                        <button
                                                            onClick={() => setPage(value => value - 1)}
                                                            style={paginationButtonStyle}>
                                                            Anterior
                                                        </button>
                                                    )}

                                                    <span style={{ fontWeight: "bold" }}>Página {page} de {totalPages}</span>

                                                    {/* Next Button */}
                                                    {page < totalPages && (
                                                        <button
                                                            onClick={() => setPage(value => value + 1)}
                                                            style={paginationButtonStyle}>
                                                            Próximo
                                                        </button>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>

                            )
                        }
                    </>
                    :
                    (
                        <>
                            <div style={{ width: "100%", display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gap: "1em", alignItems: "center", marginBottom: "1em" }}>
                                <div style={{ flex: 1, boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", borderRadius: "8px", padding: "20px", backgroundColor: "#fff" }}>
                                    <h3 style={{ fontSize: "1.2em", color: "#666", margin: "0", fontWeight: "500" }}>Atendimentos Totais</h3>
                                    <span style={{ display: "block", fontSize: "2em", fontWeight: "700", color: "#333", marginTop: "8px", textAlign: "center" }}>{atendimentos.length}</span>
                                </div>

                                <div style={{ flex: 1, boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", borderRadius: "8px", padding: "20px", backgroundColor: "#fff" }}>
                                    <h3 style={{ fontSize: "1.2em", color: "#666", margin: "0", fontWeight: "500" }}>Atendimentos Contestados</h3>
                                    <span style={{ display: "block", fontSize: "2em", fontWeight: "700", color: "#333", marginTop: "8px", textAlign: "center" }}>{atendimentosContestados.length}</span>
                                </div>

                                <div style={{ flex: 1, boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", borderRadius: "8px", padding: "20px", backgroundColor: "#fff" }}>
                                    <h3 style={{ fontSize: "1.2em", color: "#666", margin: "0", fontWeight: "500" }}>Atendimentos Cobrados</h3>
                                    <span style={{ display: "block", fontSize: "2em", fontWeight: "700", color: "#333", marginTop: "8px", textAlign: "center" }}>{atendimentosCobrados.length}</span>
                                </div>
                            </div>
                            <div style={{ width: "100%", display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr", gap: "1em", alignItems: "center", marginBottom: "20px" }}>
                                <div style={{ flex: 1, boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", borderRadius: "8px", padding: "20px", backgroundColor: "#fff" }}>
                                    <h3 style={{ fontSize: "1.2em", color: "#666", margin: "0", fontWeight: "500" }}>Valor Por Excedente</h3>
                                    <span style={{ display: "block", fontSize: "2em", fontWeight: "700", color: "#333", marginTop: "8px", textAlign: "center" }}>R$  {VPE}</span>
                                </div>
                                <div style={{ flex: 1, boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", borderRadius: "8px", padding: "20px", backgroundColor: "#fff" }}>
                                    <h3 style={{ fontSize: "1.2em", color: "#666", margin: "0", fontWeight: "500" }}>Valor Por Atendimento</h3>
                                    <span style={{ display: "block", fontSize: "2em", fontWeight: "700", color: "#333", marginTop: "8px", textAlign: "center" }}>R$  {VPA}</span>
                                </div>
                                <div style={{ flex: 1, boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", borderRadius: "8px", padding: "20px", backgroundColor: "#fff" }}>
                                    <h3 style={{ fontSize: "1.2em", color: "#666", margin: "0", fontWeight: "500" }}>Valor Por Excedente (Total)</h3>
                                    <span style={{ display: "block", fontSize: "2em", fontWeight: "700", color: "#333", marginTop: "8px", textAlign: "center" }}>R$ {VPETotal < 0 ? 0 : VPETotal}</span>
                                </div>
                                {/* <div style={{ flex: 1, boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", borderRadius: "8px", padding: "20px", backgroundColor: "#fff" }}>
                    <h3 style={{ fontSize: "1.2em", color: "#666", margin: "0", fontWeight: "500" }}>VPA Total</h3>
                    <span style={{ display: "block", fontSize: "2em", fontWeight: "700", color: "#333", marginTop: "8px", textAlign: "center" }}>R$  {VPATotal}</span>
                </div> */}
                                <div style={{ flex: 1, boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", borderRadius: "8px", padding: "20px", backgroundColor: "#fff" }}>
                                    <h3 style={{ fontSize: "1.2em", color: "#666", margin: "0", fontWeight: "500" }}>Faturamento</h3>
                                    <span style={{ display: "block", fontSize: "2em", fontWeight: "700", color: "#333", marginTop: "8px", textAlign: "center" }}>R$  {faturamento}</span>
                                </div>
                            </div>
                        </>
                    )
            }
            {
                atendimentos.length > 0 && (
                    <div style={{ width: "100%" }}>
                        <div style={{ backgroundColor: "#fff", borderRadius: "8px", boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", padding: "20px", overflow: "hidden" }}>

                            {atendimentos.length > 0 && (
                                <div>
                                    <table style={{ width: "100%", borderCollapse: "collapse" }}>
                                        <thead>
                                            <tr>
                                                {columns.map((column) => (
                                                    <th key={column.field} style={{
                                                        padding: "10px",
                                                        textAlign: "center",
                                                        backgroundColor: "#f4f4f4",
                                                        borderBottom: "2px solid #e0e0e0",
                                                        fontWeight: "bold"
                                                    }}>
                                                        {column.headerName}
                                                    </th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {paginatedAtendimentos.map((atendimento) => (
                                                <tr key={atendimento._id}>
                                                    {columns.map((column, index) => (
                                                        <td key={column + index} style={{
                                                            textAlign: "center",
                                                            padding: "10px",
                                                            borderBottom: "1px solid #e0e0e0"
                                                        }}>
                                                            {column.field == "AtendimentoCobrado" ?
                                                                (atendimento[column.field] === "S" ? "Sim" : "Não") :
                                                                atendimento[column.field]
                                                            }
                                                        </td>
                                                    ))}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>

                                    {/* Pagination */}
                                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "20px" }}>
                                        {/* Previous Button */}
                                        {page > 1 && (
                                            <button
                                                onClick={() => setPage(value => value - 1)}
                                                style={paginationButtonStyle}>
                                                Anterior
                                            </button>
                                        )}

                                        <span style={{ fontWeight: "bold" }}>Página {page} de {totalPages}</span>

                                        {/* Next Button */}
                                        {page < totalPages && (
                                            <button
                                                onClick={() => setPage(value => value + 1)}
                                                style={paginationButtonStyle}>
                                                Próximo
                                            </button>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                )
            }
        </div>)
    )
}

export default Billing